<template>
  <v-card>
    <v-card-title>
      Users
      <v-icon class="ml-2" :color="siteColor" @click="addUser">mdi-account-plus-outline</v-icon>
      <div class="flex-grow-1"></div>
      <v-text-field v-model="search" clearable label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      v-if="users.length"
      :headers="headers"
      :items="users"
      :items-per-page="30"
      item-key="id"
      class="elevation-1"
      :search="search"
      sort-by="email"
    >
      <template v-slot:body="{items}">
        <tbody>
          <template v-for="(item,i) in items">
            <tr :key="item.id">
              <!-- <td>{{ item.email }}</td> -->
              <td>
                <v-edit-dialog
                    :return-value.sync="item.email"
                    @save="editItem(item)"
                    @open="setItem(item)"
                    large
                    :color="siteColor"
                  >
                    <v-text-field
                    :color="siteColor"
                      v-model="item.email"
                      hide-details
                    ></v-text-field>
                    <template v-slot:input>
                      <v-text-field
                      :color="siteColor"
                        v-model="item.email"
                        hide-details
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
              </td>
              <td>{{ item.first }}</td>
              <td>{{ item.last }}</td>
              <td><v-select :menu-props="{ bottom: true, offsetX: true }" @change="changeUserAuth({id: item.id, auth: item.auth, location: item.location ? item.location.id : null})" :color="siteColor" single-line hide-details :items="authProfiles" v-model="item.auth"></v-select></td>
              <!-- <td>{{ item.admin }}</td> -->
              <td><v-select class="store-select" :menu-props="{ bottom: true, offsetX: true }" :readonly="item.auth !== 'admin'" @click.native.stop="" clearable @change="updateUserStore(item)" :color="siteColor" single-line hide-details return-object :items="stores" item-text="name" v-model="item.store"></v-select></td>
              <!-- <td><v-select multiple :menu-props="{ bottom: true, offsetX: true }" :readonly="item.auth !== 'admin'" @click.native.stop="" clearable @change="updateUserLocation" :color="siteColor" single-line hide-details return-object :items="locations" item-text="name" v-model="item.location"></v-select></td> -->
              <td><v-select multiple chips class="location-select" :menu-props="{ bottom: true, offsetX: true }" :readonly="item.auth !== 'admin'" @click.native.stop="" @change="updateUserLocation(item)" :color="siteColor" single-line hide-details return-object :items="locations" item-text="name" v-model="item.locations"></v-select></td>
              <td><v-select multiple chips :menu-props="{ bottom: true, offsetX: true }" readonly @click.native.stop="viewGateway" :color="siteColor" single-line hide-details return-object item-text="devEui" :items="item.gateways" v-model="item.gateways"></v-select></td>
              <td><v-select :menu-props="{ bottom: true, offsetX: true }" @change="changeDegree({id: item.id, prop: 'degreePref', value: item.degreePref, user: item})" :color="siteColor" item-text="name" single-line hide-details :items="tempProfiles" v-model="item.degreePref"></v-select></td>
              <td><v-btn @click="resetUserPassword(item, i)" :loading="loading && i == loadingIndex" :color="siteColor" dark>Reset Password</v-btn></td>
              <!-- <td><v-select @click.native.stop="" @change="changeUserLocation({id: item.id, prop: 'userLocationId', value: item.location ? item.location.id : null})" item-text="name" single-line return-object hide-details :items="locations" v-model="item.location"></v-select></td> -->
              <!-- <td>{{ item.status }}</td> -->
              <!-- <td>{{ item.sensors.length }}</td> -->
              <!-- <td>{{ capitalize(item.distributors) }}</td>
              <td>{{ capitalize(item.owner) }}</td>-->
              <!-- <td>
                <div v-for="(loc,i) in item.locations" :key="i">{{ capitalize(loc) }}</div>
              </td>-->
              <!-- <td>{{ item.approved ? 'Yes' : 'No' }}</td> -->
              <!-- <td v-if="isSuper || isDistributor">
                <v-btn
                  dark
                  x-small
                  v-if="!item.approved"
                  @click="approveUser(item.id, item.email)"
                >Approve</v-btn>
                <v-btn
                  dark
                  x-small
                  v-else-if="item.approved && item.auth !== 'super' && item.email !== user.attributes.email"
                  @click="disableUser(item.id)"
                >Disable</v-btn>
              </td> -->
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
    <!-- <td>
      <v-edit-dialog
        :return-value.sync="item.name"
        @save="editItem(item, 'name', i, $event)"
        @open="setItem(item)"
        large
        :color="siteColor"
      >
        <v-text-field
        :color="siteColor"
          v-model="item.name"
          hide-details
        ></v-text-field>
        <template v-slot:input>
          <v-text-field
          :color="siteColor"
            v-model="item.name"
            hide-details
            :readonly="isReadOnly"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </td> -->
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "users",
  data() {
    return {
      search: "",
      loading: false,
      loadingIndex: null,
      currentItem: "",
      tempProfiles: ["Fahrenheit", "Celsius"],
      authProfiles: ["super", "admin", "customer"],
      headers: [
      { text: "Email", value: "email", width: "18%" },
      { text: "First Name", value: "first" },
      {
        text: "Last Name",
        value: "last"
      },
      { text: "User Type", value: "auth", width: "10%" },
      { text: "Store", value: "store" },
      // { text: "Location", value: "location" },
      { text: "Locations", value: "locations" },
      { text: "Gateways", value: "gateways" },
      { text: "Degree Pref", value: "degreePref", width: "10%" },
      { text: "", value: "" }
      // { text: "Location", value: "locations" },
      // { text: "Status", value: "status" },
      // { text: "Sensors", value: "sensors" },
        // { text: "Distributor", value: "distributors" },
        // { text: "Owner", value: "owner" },
        // { text: "Locations", value: "locations" },
        // { text: "Approved", value: "approved" },
        // { text: "", value: "" }
      ]
    };
  },
  async created() {
    if (this.isSuper) {
      await Promise.all([this.$store.dispatch("user/getAllUsers"), this.$store.dispatch("location/allLocations"), this.$store.dispatch("store/allStores")])
    } else if (this.isDistributor) {
      this.$store.dispatch(
        "user/getDistributorUsers",
        this.userDistributorName
      );
    } else if (this.isStore) {
      this.$store.dispatch("user/getStoreUsers", this.userStoreName);
    }
  },
  computed: {
    ...mapState("user", ["user", "users", "userDetails"]),
    ...mapState("location", ["location", "locations"]),
    ...mapState("store", ["stores"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "userDistributorName",
      "userStoreName"
    ])
  },
  methods: {
    async addUser() {
      this.$swal({
      title: 'Add User',
      // input: "text",
      // inputLabel: "User Name",
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add',
      showLoaderOnConfirm: true,
      'iconColor': '#d33',
      // inputValue: "",
      html: `
        <div class="row">
          <div class="col">
            <input type="text" id="first" class="swal2-input" placeholder="First Name">
          </div>
          <div class="col">
            <input type="text" id="last" class="swal2-input" placeholder="Last Name">
          </div>
          <div class="col-12 email" style="padding-top:0;">
            <input type="text" style="margin:0;" id="email" class="swal2-input" placeholder="Email">
          </div>
        </div>
      `,
      preConfirm: async (result) => {
          let first = document.getElementById("first").value
          let last = document.getElementById("last").value
          let email = document.getElementById("email").value
          if (first && last && email) {
            let res = await this.$store.dispatch("user/adminAddUser", {
              first: first,
              last: last,
              email: email
            });
            if (!res) result = "NA"
            return result
          }
          else {
            this.$swal("Error Adding User!", "", "error");
          }
      }
      }).then(result => {
        let {isConfirmed, value} = result
        if (value === "NA") {
          this.$swal("Error Adding User!", "", "error");
        }
        else if (isConfirmed) {
          this.$swal("User Added!", "", "success");
        }
      })      
    },
    viewGateway(devEui) {
      if (!devEui.target.innerText) return
      let gw = devEui.target.innerText
      this.$router.push({ name: 'gateways', params: { gw } });
    },
    async editItem(item) {
      await this.$store.dispatch("user/adminUpdateUserEmail", {
        id: item.id,
        email: item.email
      });
    },
    setItem(item) {
      this.currentItem = { ...item }
    },
    async changeUserAuth(val) {
      await this.$store.dispatch("user/adminUpdateUserAuth", {
        id: val.id,
        auth: val.auth.toLowerCase()
      });
      if (val.location) {
        await this.updateUserLocation({
          id: val.id,
          location: null
        })
      }
    },
    async updateUserLocation(item) {
      let adminLocationAdminId = item.id
      let current = []
      let updated = []
      let removed = []
      if (item.currentLocations.length) {
        current = item.currentLocations.map(item => {
          return {
            id: item.id,
            name: item.name,
            adminLocationId: item.adminLocationId
          }
        })
      }
      if (item.locations.length) {
        updated = item.locations.map(item => {
          return {
            id: item.id,
            name: item.name
          }
        })
      }
      let added = updated.filter(item => !current.map(x => x.id).includes(item.id));
      if (!updated.length && current.length) {
        removed = current
      }
      else {
        removed = current.filter(item => !updated.map(x => x.id).includes(item.id))
      }

      if (added.length) {
        let res = await this.$store.dispatch("location/addAdminLocation", {
            adminLocationAdminId,
            adminLocationLocationId: added[0].id
        })
        current.push({
          id: res.location.id,
          name: res.location.name,
          adminLocationId: res.id
        })
      }
      else if (removed.length) {
        let res = await this.$store.dispatch("location/removeAdminLocation", {
          id: removed[0].adminLocationId
        })
        let loc = current.findIndex(item => item.id === res.location.id)
        current.splice(loc, 1)
      }
      this.$store.commit("user/updateUserLocations",{userId: adminLocationAdminId, locations: current })
    },
    async updateUserStore(item) {
      let {id, store} = item
      await this.$store.dispatch("user/updateUserStore", {id, store});
    },
    async changeDegree(val) {
      if (val.user.auth === "customer") {
        val.auth = "customer"
        val.sensors = val.user.sensors
      }
      else if (val.user.store) {
        val.auth = "store"
        val.sensors = val.user.store
      }
      await this.$store.dispatch("user/updateUserPropertySimple", val)
    },
    async resetUserPassword(val, ind) {
      if (val.admin === 'super') {
        if (val.id !== this.userDetails.id) return
      }
      this.loading = true
      this.loadingIndex = ind
      await this.$store.dispatch("user/adminResetUserPassword", { username: val.id })
      this.loading = false
    },
    async changeUserLocation(val) {
      await this.$store.dispatch("sensor/updateSensorPropertySimple", val)
    },
    async approveUser(id, email) {
      await this.$store.dispatch("user/approveUserRegistration", { id, email });
    },
    async disableUser(id) {
      await this.$store.dispatch("user/disableUserRegistration", id);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
table {
  td {
    .v-text-field {
      border-color: #ffffff1f;
      padding-top: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
  }
}
</style>